body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    background-color: #f0f2f5;
}

.topnav {
    background-color: #333;
    overflow: hidden;
    position: relative;
}

.topnav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    color: white;
}

.topnav img {
    width: 50px;
    height: auto;
    border-radius: 50%;
}

.topnav .nav-links {
    display: flex;
    gap: 20px;
    visibility: visible;
}

.topnav .nav-links a {
    padding: 14px 20px;
    display: inline-flex;
    align-items: center;
    color: white;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
}

.topnav .nav-links a:hover,
.topnav .nav-links a.active {
    background-color: #666;
    color: white;
}

@media (max-width: 768px) {
    .topnav .nav-links {
        display: none;
    }

    .topnav .menu-icon {
        display: block;
        color: white;
        font-size: 1.5rem;
        cursor: pointer;
    }
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: #333;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.content {
    transition: margin-left 0.5s;
    margin-left: 0;
}

.sidenav-open .content {
    margin-left: 250px;
}


.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: white;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    background-color: #666;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    color: white;
}

.sidenav .sidenav-icons {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

@media (max-width: 768px) {
    .topnav img {
        width: 40px;
    }
}

@media (min-width: 769px) {
    .menu-icon {
        display: none;
    }
}