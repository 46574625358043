.hero-subtitle {
    text-align: center;
    color:white;
    font-weight: bold;
    height: 80px;
    background-color: #071952;
}

.hero-body{
    text-align: center;
    font-weight: 550;
    color:brown;
}
.apply-button-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.apply-button {
    background-color: #007BFF;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.apply-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.details-section {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.details-section:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.dialog-title {
    font-weight: bold;
    color: #007BFF;
    transition: color 0.3s ease;
}

.dialog-title:hover {
    color: #0056b3;
}

.dialog-content {
    padding: 1rem;
}

.form-control {
    margin-bottom: 1rem;
}

.form-control-label {
    font-weight: bold;
}

.select-label {
    font-weight: bold;
}

.select-menu {
    font-weight: bold;
}

.program-highlights-section {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.program-highlights-image {
    max-width: 40%;
    height: auto;
    margin-right: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.program-highlights-image:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.program-highlights-content {
    flex: 1;
}

.program-highlights-title {
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    transition: color 0.3s ease;
}

.program-highlights-title:hover {
    color: #007BFF;
}

.program-highlights-list {
    list-style-type: disc;
    padding-left: 20px;
}

.program-highlights-list li {
    margin-bottom: 10px;
    font-size: 1rem;
    line-height: 1.6;
}


.details-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffffff;
    align-items: center;
}

.details-table thead {
    background-color: #333;
    color: #fff;
    text-align: left;
}

.details-table th,
.details-table td {
    padding: 12px 15px;
    text-align: left;
}

.details-table th {
    font-weight: bold;
    border-bottom: 2px solid #444;
}

.details-table tbody tr {
    transition: background-color 0.3s ease;
}

.details-table tbody tr:nth-child(even) {
    background-color: #f4f4f4;
}

.details-table tbody tr:hover {
    background-color: #e0e0e0;
    transform: scale(1.02);
}

.details-table td {
    border-bottom: 1px solid #ddd;
}

.details-table tbody tr:last-child td {
    border-bottom: none;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.details-table {
    animation: fadeIn 1s ease-in-out;
}

.internship-container {
  padding: 20px;
  max-width: 1700px;
  margin: 0 auto;
  text-align: center;
}

.internship-header {
  margin-bottom: 40px;
}

.internship-header h1 {
  font-size: 3rem;
  color: #203764;
}

.internship-header p {
  font-size: 1.2rem;
  color: #555;
}

/* Overview Section */
.internship-overview {
  margin-bottom: 40px;
}

.internship-overview h2 {
  font-size: 2rem;
  color: #203764;
  margin-bottom: 20px;
}

.internship-overview ul {
  list-style: none;
  padding: 0;
  font-size: 1.1rem;
  color: #333;
}

.internship-overview li {
  margin-bottom: 10px;
}

/* Programs Section */
.internship-programs {
  margin-bottom: 40px;
}

.internship-programs h2 {
  font-size: 2rem;
  color: brown;
  margin-bottom: 20px;
}

.program-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.program-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 300px;
  text-align: left;
}

.program-card h3 {
  font-size: 1.5rem;
  color: #203764;
  margin-bottom: 10px;
}

.program-card p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.apply-button {
  background-color: #95b1e7;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-button:hover {
  background-color: #6a8bcd;
}

.internship-testimonials {
  margin-bottom: 40px;
}

.internship-testimonials h2 {
  font-size: 2rem;
  color: #203764;
  margin-bottom: 20px;
}

.testimonial-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.testimonial-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 500px;
  text-align: center;
}

.testimonial-card p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

/* Footer Section */
.internship-footer {
  margin-top: 40px;
}

.internship-footer h2 {
  font-size: 2rem;
  color: #203764;
  margin-bottom: 20px;
}

.internship-footer p {
  font-size: 1.1rem;
  color: #333;
}

.internship-footer a {
  color: #95b1e7;
  text-decoration: none;
}

.internship-footer a:hover {
  text-decoration: underline;
}

.intern {
    display: flex;
    align-items: flex-start;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    background-color: powderblue;;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

@media (max-width: 768px) {
  .intern {
      flex-direction: column; 
  }

  .cloudimg {
      order: 1; 
      margin-bottom: 20px; 
  }

  .cloudcontent {
      order: 2; 
  }

  .apply-button {
      width: 100%;
      margin-top: 20px;
  }
  .close-icon{
    margin-left: 250px;
  }
}