.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.contact-style-two-items {
    padding: 60px 30px;
    background: linear-gradient(to right, #00c6ff, #0072ff);
    color: #fff;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-top: 50px;
}

.contact-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 20px;
}

.contact-item {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 350px; 
    width: 100%;
    margin: 0 auto; 
}

.contact-style-two .item {
    background: rgba(255, 255, 255, 0.2);
    padding: 30px;
    border-radius: 15px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
    height: 100%;
}

.contact-style-two .item:hover {
    background: rgba(255, 255, 255, 0.3);
}

.contact-style-two .icon {
    font-size: 90px; 
    margin-bottom: 15px;
    display: block; 
}

.contact-style-two .title {
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: bold;
}

.contact-style-two p {
    margin-bottom: 20px;
}

.contact-style-two a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
}

.contact-style-two a:hover {
    color: black;
    text-decoration: underline;
}

.contact-style-two i {
    font-size: 40px;
    margin-bottom: 15px;
    display: block; 
}


.form-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

/* .image-container img {
    max-width: 100%;
    height: auto;
    margin-left: 300px;
} */
.image-container1 img {
    max-width: 100%;
    height: 360px;
    margin-left: 433px;
}
.form-container {
    max-width: 600px;
    width: 100%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 200px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 95%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-group .error {
    color: red;
    font-size: 14px;
}

button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:disabled {
    background-color: #ccc;
}

.thank-you-message {
    color: green;
    font-size: 16px;
}
@media (max-width: 768px) {
    .contact-row {
        flex-direction: column; 
        gap: 10px; 
    }

    .contact-item {
        margin-bottom: 20px; 
    }

    .contact-style-two-items {
        padding: 30px 15px; 
    }

    .contact-style-two .title {
        font-size: 18px;
    }

    .contact-style-two i {
        font-size: 32px; 
    }
    .image-container1 img {
        /* max-width: 100%;
        height: auto;
        margin-left: 0px; */
        max-width: 100%;
    height: 360px;
    margin-left: 0px;
    }   

    .form-image-container {
        display: block;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .form-container {
        padding: 15px; 
        margin-right: 0px;
    }

    .form-group input,
    .form-group textarea {
        padding: 8px; 
    }
}

@media (max-width: 480px) {
    .contact-style-two .title {
        font-size: 16px; 
    }

    .contact-style-two i {
        font-size: 28px;
    }

    .form-container {
        padding: 10px; 
    }

    button {
        width: 100%; 
        padding: 12px;
    }
}