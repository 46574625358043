.kp-container {
    font-family: Arial, sans-serif;
  }
  
  .kp-hero-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: linear-gradient(to right, #f0f8ff, #e6f7ff);
    border-radius: 10px;
    margin: 20px;
    position: relative;
    overflow: hidden;
  }
  
  .kp-hero-content {
    max-width: 50%;
  }
  
  .kp-hero-title {
    font-size: 2.5rem;
    color: #003f6c;
    margin-bottom: 10px;
  }
  
  .kp-hero-description {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .kp-hero-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #003f6c;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .kp-hero-button:hover {
    background-color: #00509e;
  }
  
  .kp-hero-image {
    max-width: 45%;
    border-radius: 10px;
    animation: slideIn 1s ease-out;
  }
  
  @keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
  }
  
  .kp-features-section {
    padding: 20px;
    text-align: center;
  }
  
  .kp-features-title {
    font-size: 2rem;
    color: #003f6c;
    margin-bottom: 20px;
  }
  
  .kp-features-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .kp-feature-card {
    background: #e6f7ff;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .kp-feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .kp-feature-icon {
    font-size: 3rem;
    color: #003f6c;
    margin-bottom: 10px;
  }
  
  .kp-curriculum-section {
    padding: 20px;
    background: #f0f8ff;
  }
  
  .kp-curriculum-title {
    font-size: 2rem;
    color: #003f6c;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .kp-curriculum-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .kp-curriculum-item {
    background: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    width: 80%;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .kp-curriculum-item:hover {
    background-color: #e6f7ff;
  }
  .kp-arts-section {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
  }
  
  .kp-arts-title {
    font-size: 2rem;
    color: #003f6c;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .kp-arts-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .kp-arts-card {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
  }
  
  .kp-arts-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .kp-arts-icon {
    font-size: 3rem;
    color: #003f6c;
    margin-bottom: 10px;
  }
  /* kids program css end */
  /* Base styles remain the same */

/* Mobile view adjustments */
@media (max-width: 768px) {
    .kp-hero-section {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .kp-hero-content {
      max-width: 90%;
    }
  
    .kp-hero-image {
      max-width: 90%;
      margin-top: 20px;
    }
  
    .kp-hero-title {
      font-size: 2rem;
    }
  
    .kp-hero-description {
      font-size: 1rem;
    }
  
    .kp-hero-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  
    .kp-features-grid {
      flex-direction: column;
      align-items: center;
    }
  
    .kp-feature-card {
      width: 80%;
      margin: 10px 0;
    }
  
    .kp-curriculum-item {
      width: 90%;
    }
  
    .kp-arts-card {
      width: 80%;
      margin: 10px 0;
    }
  }
  
  @media (max-width: 480px) {
    .kp-hero-title {
      font-size: 1.5rem;
    }
  
    .kp-hero-description {
      font-size: 0.9rem;
    }
  
    .kp-hero-button {
      padding: 6px 12px;
      font-size: 0.8rem;
    }
  
    .kp-feature-card {
      width: 100%;
      margin: 10px 0;
    }
  
    .kp-curriculum-item {
      width: 100%;
    }
  
    .kp-arts-card {
      width: 100%;
      margin: 10px 0;
    }
  }