.de-container {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .de-hero-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 60px 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }
  
  .de-hero-content {
    max-width: 100%;
    flex: 1;
    padding-right: 20px;
  }
  
  .de-hero-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .de-hero-description {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #666;
  }
  
  .de-hero-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .de-hero-button:hover {
    background-color: #0056b3;
  }
  
  .de-hero-image {
    max-width: 45%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    flex: 1;
  }
  
  @media (max-width: 768px) {
    .de-hero-section {
      flex-direction: column;
      text-align: center;
    }
  
    .de-hero-content {
      padding-right: 0;
      max-width: 100%;
    }
  
    .de-hero-image {
      max-width: 100%;
      margin-top: 20px;
    }
  }
  
  .de-services-section {
    padding: 60px 20px;
    background: #ffffff;
    text-align: center;
  }
  
  .de-services-title {
    font-size: 2.2rem;
    margin-bottom: 40px;
    color: #007bff;
  }
  
  .de-services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .de-service-card {
    background: #007bff;
    color: white;
    padding: 20px;
    width: 250px;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .de-service-card:hover {
    transform: translateY(-10px);
    background-color: #0056b3;
  }
  
  .de-service-icon {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }
  
  .de-service-card h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
  
  .de-service-card p {
    font-size: 1rem;
  }
  
  /* Process Section */
  .de-process-section {
    padding: 60px 20px;
    background: #eaf2f8;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .de-process-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    font-weight: bold;
    color: #007bff;
  }
  
  .de-process-steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .de-process-step {
    background: white;
    padding: 20px;
    width: 280px;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
    position: relative;
    overflow: hidden;
  }
  
  .de-process-step:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
    background-color: #f0f9ff;
  }
  
  .de-step-number {
    font-size: 2rem;
    font-weight: bold;
    background: #007bff;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    position: absolute;
    top: 8px;
    left: 15px;
  }
  
  .de-process-step h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
    margin-left: 45px;
  }
  
  .de-process-step p {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
    margin-top: 25px;
  }