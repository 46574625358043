.mobileapp-container {
    font-family: 'Poppins', sans-serif;
    background-color: #f5f5fa;
    color: #333;
  }
  
  .mobileapp-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 60px 20px;
    color: white;
    background-color: #6366f1;
    position: relative;
  }
  
  .mobileapp-img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
  }
  
  .mobileapp-img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }
  
  .hero-title {
    font-size: 3rem;
    margin-bottom: 20px;
    animation: fadeInDown 1s ease-out;
  }
  
  .hero-description {
    font-size: 1.5rem;
    max-width: 700px;
    margin-bottom: 40px;
  }
  
  .hero-button {
    padding: 12px 28px;
    background-color: #4c51bf;
    border: none;
    color: white;
    font-size: 1.2rem;
    border-radius: 25px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .hero-button:hover {
    background-color: #3c366b;
  }
  
  @keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
  }
    .mobileapp-content {
    padding: 40px 20px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 40px auto;
    max-width: 1200px;
  }
  
  .process-step {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .process-step:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
  
  .process-icon {
    font-size: 3rem;
    color: #6366f1;
    margin-right: 20px;
  }
  
  .process-step h3 {
    font-size: 1.8rem;
    color: #4c51bf;
    margin-bottom: 10px;
  }
  
  .mobileapp-features {
    padding: 40px 0;
    background-color: #f5f5fa;
    text-align: center;
  }
  
  .features-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 30px;
  }
  
  .feature-box {
    background-color: white;
    padding: 20px;
    width: 260px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
  
  .feature-box i {
    font-size: 2.5rem;
    color: #4c51bf;
    margin-bottom: 15px;
  }
  
  .feature-box h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }