.about-container {
    background-color: #071952;
    height: 80px;
    width: 100%;
  }
    .header-data {
    font-size: 24px;
    color: white;
    font-weight: bold;
    text-align: center;
    padding-top: 20px;
  }
    .why-choose-us {
    text-align: center;
    margin: 40px 0;
  }
  
  .why-choose-us h2 {
    color: #088395;
    font-size: 1.75rem;
    margin-bottom: 20px;
  }
    .features {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .feature {
    flex: 1 1 300px;
    text-align: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .feature:hover {
    transform: scale(1.05);
  }
  
  .feature img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
    .vision-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: antiquewhite;
  }
  
  .image-container {
    flex: 1;
    animation: fadeInFromTop 3s ease-out forwards;
    animation-delay: 0.5s;
  }
  
  .content-container {
    flex: 3;
    /* padding-left: 20px; */
    animation: fadeInFromTop 5s ease-out forwards;
    animation-delay: 1s;
  }
  
  .image-container img {
    max-width: 100%;
    height: 250px;
    margin-left: 20px;
  }
  
  h1 {
    margin-top: 0;
  }
  
  p {
    margin-bottom: 0;
  }
  
  .meet-the-team {
    background-color: #f4f4f4;
    padding: 20px;
  }
  
  .team-members {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .team-member {
    flex: 1 1 calc(25% - 40px);
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .team-member img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
  
  .team-member h3 {
    margin: 10px 0;
  }
  
  .loading-text {
    display: inline-block;
    animation: loading 1.5s infinite;
  }
  
  .feature-icon {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    
    .header-data {
      font-size: 20px;
      padding-top: 15px; 
    }
  
    .why-choose-us h2 {
      font-size: 1.5rem; 
      margin-bottom: 15px; 
    }
  
    .features {
      flex-direction: column; 
      align-items: center; 
      padding: 0 10px; 
    }
  
    .feature {
      width: 100%; 
      max-width: 300px;
      padding: 15px; 
    }
  
    .feature img {
      width: 40px; 
      height: 40px; 
    }
  
    .vision-data {
      flex-direction: column; 
      text-align: center;
      padding: 15px;
    }
  
    .image-container {
      width: 100%; 
      animation: fadeInFromTop 2s ease-out forwards; 
      animation-delay: 0.5s;
    }
  
    .content-container {
      width: 100%; 
      padding-left: 0; 
      animation: fadeInFromTop 2.5s ease-out forwards; 
      animation-delay: 0.75s;
    }
  
    .team-members {
      flex-direction: column; 
      align-items: center; 
    }
  
    .team-member {
      width: 100%; 
      max-width: 300px; 
      padding: 15px;
    }
  
    .team-member img {
      width: 80%; 
    }
  }
  
  @media (max-width: 480px) {
    .header-data {
      font-size: 18px; 
      padding-top: 10px; 
    }
  
    .why-choose-us h2 {
      font-size: 1.25rem; 
      margin-bottom: 10px; 
    }
  
    .feature {
      padding: 10px; 
    }
  
    .feature img {
      width: 30px; 
      height: 30px; 
    }
  
    .team-member {
      padding: 10px;
    }
  
    .team-member img {
      width: 70%; 
    }
  }