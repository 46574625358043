.imagegrid {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    margin: 0 auto;
    text-align: center; 
    border-radius: 2px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    gap: 50px;
    height: 400px;
    flex-wrap: wrap;
    justify-content: center;
}

.webimg1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.webimg1 img {
    width: 400px;
    height: 300px;
    object-fit: cover;
    margin-bottom: 10px; 
    cursor: pointer;
}

.webimg1 p:hover {
    border-bottom: 2px solid red;
    cursor: pointer;
}

.content-animation1 {
    width: 100%;
    height: 80px; 
    overflow: hidden;
    background-color: antiquewhite;
}

.text-animation1 {
    display: inline-block;
    white-space: nowrap;
    margin-top: 30px;
    animation: moveText 10s linear infinite; 
    color: #071952;
    font-weight: bold;
    font-size: 18px;
}

@keyframes moveText {
    0% {
        transform: translateX(-1%);
    }
    100% {
        transform: translateX(143%);
    }
}

@media (max-width: 768px) {
    .imagegrid {
        padding-top: 20px; 
        height: auto; 
        gap: 20px; 
    }

    .webimg1 img {
        width: 100%;
        height: auto;
        max-width: 100%; 
    }

    .content-animation1 {
        height: 60px; 
    }

    .text-animation1 {
        font-size: 16px;
        margin-top: 20px;
    }
    @keyframes moveText {
        0% {
            transform: translateX(-1%);
        }
        100% {
            transform: translateX(80%);
        }
    }
}

@media (max-width: 480px) {
    .imagegrid {
        padding-top: 10px; 
        gap: 10px;
    }

    .webimg1 img {
        width: 100%; 
    }

    .text-animation1 {
        font-size: 14px; 
        margin-top: 10px; 
    }
}