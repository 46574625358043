.cloudcomputing-container {
    font-family: 'Poppins', sans-serif;
    color: #333;
    background-color: #f4f6f9;
  }
  
  .cloudcomputing-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 60px 20px;
    background-color: #1e3a8a;
    color: white;
    position: relative;
    overflow: hidden;
  }
  
  .hero-img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.4s ease;
  }
  
  .hero-img:hover {
    transform: scale(1.05);
  }
  
  .hero-title {
    font-size: 3rem;
    margin-bottom: 20px;
    animation: fadeInUp 1s ease-out;
  }
  
  .hero-description {
    font-size: 1.3rem;
    max-width: 700px;
    margin-bottom: 30px;
  }
  
  .hero-button {
    padding: 12px 28px;
    background-color: #4f46e5;
    border: none;
    color: white;
    font-size: 1.2rem;
    border-radius: 25px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .hero-button:hover {
    background-color: #4338ca;
  }
  
  .cloudcomputing-main {
    padding: 60px 20px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 40px auto;
    max-width: 1200px;
  }
  
  .feature-block {
    padding: 30px 0;
    text-align: center;
  }
  
  .feature-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
  
  .feature-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .feature-item {
    background: #fff;
    padding: 20px;
    width: 280px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
  
  .feature-icon {
    font-size: 2.5rem;
    color: #1e40af;
    margin-bottom: 15px;
  }
  
  .process {
    padding: 40px 0;
    background-color: #e5e7eb;
  }
  
  .process-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .process-steps {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .process-step {
    display: flex;
    align-items: center;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .step-icon {
    font-size: 2.5rem;
    color: #4f46e5;
    margin-right: 20px;
  }
  
  .process-step:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
  
  .cloud-benefits {
    padding: 40px 0;
    text-align: center;
  }
  
  .benefits-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
  
  .benefits-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .benefit-item {
    background: #fff;
    padding: 20px;
    width: 280px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  @keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
  }