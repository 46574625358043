.wd-container {
    font-family: Arial, sans-serif;
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .wd-hero-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 20px;
    background: linear-gradient(to right, #e0f7fa, #b2ebf2);
    border-radius: 10px;
    margin-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .wd-hero-content {
    max-width: 50%;
  }
  
  .wd-hero-title {
    font-size: 2.5rem;
    color: #00796b;
    margin-bottom: 20px;
    animation: fadeIn 2s ease-out;
  }
  
  .wd-hero-description {
    font-size: 1.2rem;
    color: #004d40;
    margin-bottom: 20px;
  }
  
  .wd-hero-button {
    background-color: #00796b;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .wd-hero-button:hover {
    background-color: #004d40;
    transform: scale(1.05);
  }
  
  .wd-hero-image {
    max-width: 40%;
    border-radius: 10px;
  }
  
  .wd-services-section {
    margin-bottom: 40px;
  }
  
  .wd-services-title {
    font-size: 2rem;
    color: #00796b;
    margin-bottom: 20px;
    text-align: center;
    animation: fadeIn 2s ease-out;
  }
  
  .wd-services-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .wd-service-card {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    animation: fadeIn 2s ease-out;
  }
  
  .wd-service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .wd-service-icon {
    font-size: 3rem;
    color: #00796b;
    margin-bottom: 10px;
  }
  
  .wd-process-section {
    margin-bottom: 40px;
  }
  
  .wd-process-title {
    font-size: 2rem;
    color: #00796b;
    margin-bottom: 20px;
    text-align: center;
    animation: fadeIn 2s ease-out;
  }
  
  .wd-process-steps {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .wd-process-step {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 23%;
    margin: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    animation: fadeIn 2s ease-out;
  }
  
  .wd-process-step:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .wd-step-number {
    background: #00796b;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px auto;
    font-size: 1.5rem;
  }
    @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
  .slider {
    overflow: hidden;
    width: 100%;
    height: 135px;
    background-color: gainsboro;
    position: relative;
  }
  
  .slider-track {
    display: flex;
    animation: slide 10s linear infinite;
    margin-top: 25px;
  }
  
  .loading-img {
    width: 150px;
    height: 100%; 
    flex-shrink: 0;
    filter: grayscale(100%);
    transition: filter 0.3s ease-in-out;
  }
  
  .loading-img:hover {
    filter: grayscale(0%);
  }
  
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-1800px); 
    }
  }
  
    .headertext{
      color:#f7682f;
      text-align: center;
      font-size: 1.8rem;
    }
    .loading-img {
      width: 100px; 
      height: 80px; 
      margin: 0 10px;
    }
    
    @keyframes slide {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }

@media (max-width: 768px) {
    .wd-container {
      padding: 0 10px;
    }
  
    .wd-hero-section {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .wd-hero-content {
      max-width: 90%;
    }
  
    .wd-hero-title {
      font-size: 2rem;
    }
  
    .wd-hero-description {
      font-size: 1rem;
    }
  
    .wd-hero-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  
    .wd-hero-image {
      max-width: 80%;
      margin-top: 20px;
    }
  
    .wd-services-grid {
      flex-direction: column;
      align-items: center;
    }
  
    .wd-service-card {
      width: 90%;
      margin: 10px 0;
    }
  
    .wd-process-steps {
      flex-direction: column;
      align-items: center;
    }
  
    .wd-process-step {
      width: 90%;
      margin: 10px 0;
    }
  
    .wd-step-number {
      width: 35px;
      height: 35px;
      font-size: 1.2rem;
    }
  
    .slider {
      height: 100px; 
    }
  
    .loading-img {
      width: 80px; 
      height: 60px; 
    }
  }
  
  @media (max-width: 480px) {
    .wd-container {
      padding: 0 5px;
    }
  
    .wd-hero-title {
      font-size: 1.5rem;
    }
  
    .wd-hero-description {
      font-size: 0.9rem;
    }
  
    .wd-hero-button {
      padding: 6px 12px;
      font-size: 0.8rem;
    }
  
    .wd-hero-image {
      max-width: 90%;
    }
  
    .wd-service-card {
      width: 100%;
    }
  
    .wd-process-step {
      width: 100%;
    }
  
    .wd-step-number {
      width: 30px;
      height: 30px;
      font-size: 1rem;
    }
  
    .slider {
      height: 80px; 
    }
  
    .loading-img {
      width: 60px; 
      height: 50px;
    }
  }