/* Main container styling for desktop */
.contact-us-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1500px;
    padding: 10px;
}

/* Styling for Contact Us and Art sections for desktop */
.art-section {
    width: 48%;
    padding: 20px;
}


.arts-header {
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    height: 22vh;
    text-align: center;
    margin-top: 15px;
}

.arts-data {
    width: 750px;
}
.arts-data p{
    font-weight: bold;
    color: #088395;
    font-size: 20px;
    margin-top: -8px;
}

.img-card {
    height: 150px;
}

.header-content h1 {
    font-size: 3rem;
    animation: fadeIn 2s ease-in;
    color: #071952;
}

.header-content p {
    font-size: 1.2rem;
    margin-top: -25px;
    animation: fadeIn 2s ease-in 1s;
    color: #071952;
}

.btn-cta {
    display: inline-block;
    margin-top: 25px;
    padding: 10px 20px;
    background-color: #f39c12;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

/* @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
} */

.art-categories {
    text-align: center;
    padding: 0px 20px;
    margin-top: -15px;
}

.category-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.card {
    width: 250px;
    margin: 20px;
    text-align: center;
    /* transition: transform 0.3s ease; */
}

.card:hover {
    transform: scale(1.1);
}

.card img {
    width: 100%;
    border-radius: 10px;
}

.card h3 {
    margin-top: 10px;
    font-size: 1.5rem;
}

.art-gallery {
    padding: 0px 20px;
    text-align: center;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.gallery-item {
    overflow: hidden;
}

.gallery-item img {
    width: 100%;
    transition: transform 0.3s ease;
}

.gallery-item:hover img {
    transform: scale(1.2);
}

.about-arts {
    text-align: center;
}

.about-content h2 {
    font-size: 2.5rem;
    animation: fadeInUp 2s ease-out;
}

.about-content p {
    font-size: 1.2rem;
    margin-top: 20px;
    animation: fadeInUp 2s ease-out 1s;
}

/* @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); } */
/* } */

.links-container {
    padding: 1rem;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease-in-out; 
}

.links-container:hover {
    background-color: #e0e0e0; 
}

.header {
    text-align: center;
    margin-bottom: 1rem;
    color: #333; 
}

.links-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
}

.links-list li {
    margin: 0.5rem;
}

.links-list a {
    text-decoration: none;
    color: #007bff; 
    font-size: 1rem;
    font-weight: 500;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; 
}

.links-list a:hover {
    color: #0056b3;
    transform: translateY(-3px); 
}

.art-quotes {
    background-color: #f1f1f1;
    padding: 50px 20px;
    text-align: center;
}

.quotes-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.contact-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1300px;
    padding: 50px;
}
.arts-image {
    flex: 2 1;
    padding: 10px;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    height: 60vh;
}

.contact-us-data {
    /* display: flex; */
    /* align-items: flex-start; */
    /* overflow: hidden; */
    /* margin-top: -50px; */
    /* transition: transform 0.3s; */
    width: 500px;
    /* padding: 30px; */
    /* margin-right:20px; */
}

.contact-us-data:hover {
    transform: translateY(-10px);
}

blockquote {
    padding: 20px;
    margin: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    /* transition: transform 0.3s ease; */
}

blockquote:hover {
    transform: scale(1.05);
}

.form-container {
    margin-top: 25px;
    margin-left: 115px;
    width: 150vh;
}

.arts-form-container {
    margin-top: 25px;
    margin-left: 200px;
    width: 90vh;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; 
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Mobile styles */
@media (max-width: 768px) {
    .arts-header {
        height: 30vh; /* Increase height for mobile */
    }

    .header-content h1 {
        font-size: 2rem; /* Smaller heading */
    }

    .header-content p {
        font-size: 1rem; /* Smaller paragraph */
    }

    .btn-cta {
        padding: 8px 15px; /* Smaller button */
        font-size: 0.9rem; /* Smaller button text */
    }

    .category-cards {
        flex-direction: column; /* Stack cards vertically */
        align-items: center;
    }

    .card {
        width: 100%; /* Full width for cards */
        margin: 10px 0; /* Vertical margin */
    }

    .gallery-grid {
        grid-template-columns: repeat(2, 1fr); /* Two columns for mobile */
    }

    .about-content h2 {
        font-size: 2rem; /* Smaller heading for about section */
    }

    .about-content p {
        font-size: 1rem; /* Smaller paragraph for about section */
    }
    .form-container, .arts-form-container {
        margin-left: 0; /* Remove left margin */
        width: 100%; /* Full width */
    }

    .video-container {
        height: auto; /* Adjust video container height */
    }

    blockquote {
        width: 90%; /* Full width for blockquote */
    }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .contact-info{
        display: block;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        max-width: 1500px;
        padding: 10px;
    }
    .contact-us-container {
        flex-direction: column;
    }

    .contact-us-data {
        width: 100%;
        margin: 0;
        padding: 10px;
    }
    .arts-data {
        width: 300px;
        margin-left: 50px;
    }
    .art-section {
        width: 200px;

    }

    .art-image {
        height: auto;
        margin-bottom: 20px;
    }
}

/* Button styles */
.whatsapp-button {
    margin-top: 70px;
    background-color: #039dfc;
    width: 100%;
}

/* Icon styles */
.icon-style {
    margin-left: 10px;
    color: #039dfc;
}

.contact-link {
    color: #039dfc;
    font-weight: bold;
    text-decoration: none;
    margin-left: 10px;
}
