.main-content {
    padding: 10px;
    animation: fadeIn 1.5s ease-in-out;
}

.hero {
    width: 100%;
    height: 20vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    position: relative;
    animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.hero-content h1 {
  font-size: 50px; 
  animation: fadeIn 1s ease-in-out;
  margin-top: 10px;

}

.hero-content h4 {
  font-size: 20px;
  animation: fadeIn 1s ease-in-out;
}



.hero:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.vision, .passion {
  text-align: center;
  margin: 30px 0;
  animation: bounce 2s ease;
}

.vision p {
  font-size: 35px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  color: #071952;
}

.passion h4 {
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  margin: 10px 0;
  color: #088395;
  font-weight: bold;
}

.contenthead, .management, .cloudsolutions, .webdevelopment {
    display: flex;
    flex-direction: row; 
    align-items: center; 
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.contenthead:hover, .management:hover, .cloudsolutions:hover, .webdevelopment:hover {
    transform: translateY(-10px);
}

.subheaderimg, .submanageimg, .cloudimg, .webimg {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center; 
    justify-content: center; 
}

.subheaderimg img, .submanageimg img, .cloudimg img, .webimg img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.subheadercontent, .submanagecontent, .cloudcontent, .webcontent {
    flex: 2;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
}

.subheadercontent h4, .submanagecontent h4, .cloudcontent h4, .webcontent h4 {
    font-size: 22px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    color:#088395;
}

.subheadercontent h2 {
    color: #071952;
}

.subheadercontent p, .submanagecontent p, .cloudcontent p, .webcontent p {
    color: #666;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.icon-text {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.icon-text .icon {
    font-size: 24px;
    color:#088395;
}

@media (max-width: 768px) {
  .hero {
    height: 30vh; 
  }

  .hero-content h1 {
    font-size: 24px; 
  }

  .hero-content h4 {
    font-size: 16px; 
  }

  .vision p {
    font-size: 28px; 
  }

  .passion h4 {
    font-size: 16px; 
  }

  .contenthead, .cloudsolutions {
    flex-direction: column; 
    align-items: center; 
    padding: 10px;
  }
.management,.webdevelopment{
  flex-direction: column-reverse; 
  align-items: center; 
  padding: 10px;
}
  .subheaderimg, .submanageimg, .cloudimg, .webimg {
    width: 100%; 
    padding: 0; 
  }

  .subheadercontent, .submanagecontent, .cloudcontent, .webcontent {
    width: 100%; 
    padding: 10px; 
  }

  .subheadercontent h4, .submanagecontent h4, .cloudcontent h4, .webcontent h4 {
    font-size: 18px; 
  }

  .subheadercontent h2 {
    font-size: 20px; 
  }

  .subheadercontent p, .submanagecontent p, .cloudcontent p, .webcontent p {
    font-size: 14px;
  }

  .icon-text .icon {
    font-size: 20px; 
  }
}

@media (max-width: 480px) {
  .hero {
    height: 25vh; 
  }

  .hero-content h1 {
    font-size: 20px; 
  }

  .hero-content h4 {
    font-size: 14px;
  }

  .vision p {
    font-size: 24px; 
  }

  .passion h4 {
    font-size: 14px; 
  }
}