.footer {
    background-color: #071952;
    color: white;
    padding: 40px 20px;
    text-align: center;
    font-family: 'Arial', sans-serif;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap; 
}

.footer-section {
    flex: 1;
    text-align: left;
    margin-right: 40px;
    margin-bottom: 20px; 
    min-width: 200px; 
}

.footer h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #fff;
}

.footer p {
    font-size: 16px;
    line-height: 1.5;
    color: #ccc;
}

.footer .social-icons {
    margin-top: 20px;
}

.footer .social-icons a {
    color: white;
    font-size: 24px;
    margin: 0 10px;
    transition: color 0.3s;
}

.footer .social-icons a:hover {
    color: #088395;
}

.service-icons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.service-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #088395; 
}

.service-icon .icon {
    font-size: 24px;
}

.service-icon span {
    font-size: 16px; 
    color: #ccc;
}
.footer-section a {
  text-decoration: none;
  color: inherit; 
}

.footer-section a:hover {
  color: #95b1e7; 
}

/* For tablets and smaller screens */
@media (max-width: 768px) {
  .footer-content {
      flex-direction: column;
      text-align: center;
  }

  .footer-section {
      margin-right: 0;
      text-align: center;
      margin-bottom: 30px;
  }

  .service-icons {
      align-items: center;
      justify-content: center;
  }

  .footer-section h2, 
  .footer-section p {
      text-align: center;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .footer {
      padding: 20px 10px;
  }

  .footer-section {
      margin-bottom: 20px;
  }

  .service-icons {
      flex-direction: column;
      align-items: center;
      gap: 10px;
  }

  .service-icon {
      display: flex;
      align-items: center;
      gap: 10px; /* Keep space between icon and text */
      flex-direction: row; /* Ensure icon and text are in a row */
  }

  .service-icon span {
      font-size: 14px;
  }

  .footer h2 {
      font-size: 20px;
  }

  .footer p {
      font-size: 14px;
  }

  .footer .social-icons a {
      font-size: 20px;
  }
}